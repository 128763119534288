<template>
  <Dialog
    v-model="dialog"
    btn-text="Delete"
    btn-icon="fa-trash-alt"
    data-test="deleteVersion"
    btn-color="error"
    btn-id="dummypackage-delete"
    title="Really delete version?"
  >
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <v-card-text>
      You are about to delete version {{ version.number }} of this package. This
      action cannot be undone. Are you sure you want to proceed?
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="error" :loading="loading" @click="deleteVersion"
        >Delete version</v-btn
      >
    </v-card-actions>
  </Dialog>
</template>

<script>
import { mutations } from "@/gql";
import Dialog from "@/components/dialogs/Dialog";
const { deleteVersion: mutation } = mutations;

export default {
  props: ["version"],
  components: { Dialog },
  data: () => ({
    error: false,
    errorMessage: null,
    loading: false,
    dialog: false,
  }),
  methods: {
    deleteVersion() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: {
            pk: this.version.id,
          },
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
          this.loading = false;
        })
        .then((result) => {
          if (result) {
            this.dialog = false;
            this.loading = false;
            this.$emit("delete");
          }
        });
    },
  },
};
</script>
