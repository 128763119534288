<template>
  <Dialog
    v-model="dialog"
    btn-text="View"
    btn-icon="fa-eye"
    title="Version metadata"
  >
    <v-card-text>
      <v-row>
        <template v-for="(val, key) in metadata">
          <v-col cols="3" :key="`left-${key}`" v-if="val">{{ key }}</v-col>
          <v-col
            cols="9"
            :key="`right-${key}`"
            v-if="val && typeof val === 'string'"
            v-html="$marked(val)"
          />
          <v-col cols="9" :key="`right-${key}`" v-else-if="val">
            <v-list dense class="pa-0 mt-0">
              <v-list-item v-for="(v, k) in val" :key="k" class="px-0">
                <v-list-item-content>
                  <v-list-item-title>{{ v }}</v-list-item-title>
                  <v-list-item-subtitle>{{ k }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </Dialog>
</template>

<script>
import Dialog from "@/components/dialogs/Dialog";
export default {
  components: { Dialog },
  props: ["metadata"],
  data: () => ({
    dialog: false,
  }),
};
</script>

<style scoped>
.nice-button {
  text-transform: none !important;
  letter-spacing: 0;
}
</style>
