var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"color":"success","top":"","right":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',{staticClass:"white--text"},[_vm._v("Copied to clipboard!")])]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.versions,"item-key":"name"},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.pkg.name)+"=="+_vm._s(item.number)+" ")]}},{key:"item.fileSize",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fileSize")(item.fileSize))+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("niceDate")(item.created))+" ")]}},{key:"item.install",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.installMethod(item.number)),expression:"installMethod(item.number)"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.clipboardSuccessHandler),expression:"clipboardSuccessHandler",arg:"success"}],staticClass:"nice-button px-0 primary--text",attrs:{"id":"dummypackage-install","text":""}},[_c('v-icon',{staticClass:"pr-1",attrs:{"small":""}},[_vm._v("fa fa-clone")]),_vm._v(" Copy ")],1)]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('default-button',{attrs:{"icon":"fa-download","href":item.fileUrl,"id":"dummypackage-download"}},[_vm._v("Download")])]}},{key:"item.metadata",fn:function(ref){
var item = ref.item;
return [_c('metadata-dialog',{attrs:{"metadata":item.metadata}})]}},{key:"item.vulnerabilities",fn:function(ref){
var item = ref.item;
return [_c('vulnerability-dialog',{attrs:{"vulnerabilities":item.vulnerabilities,"number":item.number}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('delete-version-dialog',{attrs:{"version":item},on:{"delete":_vm.handleDelete}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }