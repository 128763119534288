<template>
  <v-row class="ma-4" v-if="pythonPackage">
    <page-header>Python package: {{ pythonPackage.name }}</page-header>
    <v-col cols="12">
      <v-card tile>
        <table-header title="Package versions">
          <edit-python-permissions :pkg="pythonPackage" />
          <default-button
            icon="fa-sync"
            @click="$apollo.queries.pythonPackage.refetch()"
            >Refresh</default-button
          >
        </table-header>
        <v-card-text>
          <python-package-versions
            :versions="pythonPackage.versions"
            :pkg="pythonPackage"
            @refresh="$apollo.queries.pythonPackage.refetch()"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { queries } from "@/gql";
import PythonPackageVersions from "@/components/tables/PythonPackageVersions";
import PageHeader from "@/components/widgets/PageHeader";
import TableHeader from "@/components/widgets/TableHeader";
import DefaultButton from "@/components/widgets/DefaultButton";
import EditPythonPermissions from "@/components/dialogs/EditPythonPermissions";
import { dummyPackageSteps as steps, defaultOptions as options } from "@/tour";

const { pythonPackage: query, newAccount } = queries;

export default {
  components: {
    EditPythonPermissions,
    DefaultButton,
    TableHeader,
    PageHeader,
    PythonPackageVersions,
  },
  mounted() {
    this.$intro().exit();
  },
  watch: {
    pythonPackage() {
      if (this.pythonPackage.name === "dummy-package" && this.newAccount) {
        console.log("yep");
        setTimeout(() => {
          const self = this;
          const intro = self.$intro();
          intro.setOptions({ steps, ...options });
          intro.start();
        }, 500);
      }
    },
  },
  apollo: {
    newAccount,
    pythonPackage: {
      query,
      variables() {
        return { pk: this.$route.params.id };
      },
      error() {
        this.router.push("/python");
        this.router.go();
      },
    },
  },
};
</script>
