<template>
  <Dialog
    :title="`Vulnerabilities for version ${number}`"
    :btn-text="hasVulnerabilities ? `Show (${vulnerabilities.length})` : ``"
    btn-id="dummypackage-security"
    v-model="dialog"
    :disabled="!hasVulnerabilities"
    :btn-icon="hasVulnerabilities && 'fa-shield-alt'"
  >
    <v-card-text>
      <v-data-table
        dense
        :headers="headers"
        :items="vulnerabilities"
        item-key="name"
        class="elevation-0"
      >
      </v-data-table>
    </v-card-text>
  </Dialog>
</template>
<script>
import Dialog from "@/components/dialogs/Dialog";
export default {
  props: ["vulnerabilities", "number"],
  components: { Dialog },
  computed: {
    hasVulnerabilities() {
      return this.vulnerabilities && this.vulnerabilities.length > 0;
    },
  },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Spec",
        value: "spec",
      },
      {
        text: "Advisory",
        value: "advisory",
      },
      {
        text: "CVSSV2",
        value: "cvssv2",
      },
      {
        text: "CVSSV3",
        value: "cvssv3",
      },
    ],
  }),
};
</script>
