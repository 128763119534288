<template>
  <div>
    <v-snackbar v-model="snackbar" color="success" top right>
      <span class="white--text">Copied to clipboard!</span>
    </v-snackbar>
    <v-data-table
      dense
      :headers="headers"
      :items="versions"
      item-key="name"
      class="elevation-0"
    >
      <template v-slot:item.number="{ item }">
        {{ pkg.name }}=={{ item.number }}
      </template>
      <template v-slot:item.fileSize="{ item }">
        {{ item.fileSize | fileSize }}
      </template>
      <template v-slot:item.created="{ item }">
        {{ item.created | niceDate }}
      </template>
      <template v-slot:item.install="{ item }">
        <v-btn
          id="dummypackage-install"
          text
          class="nice-button px-0 primary--text"
          v-clipboard="installMethod(item.number)"
          v-clipboard:success="clipboardSuccessHandler"
        >
          <v-icon small class="pr-1">fa fa-clone</v-icon>
          Copy
        </v-btn>
      </template>
      <template v-slot:item.download="{ item }">
        <default-button
          icon="fa-download"
          :href="item.fileUrl"
          id="dummypackage-download"
          >Download</default-button
        >
      </template>
      <template v-slot:item.metadata="{ item }">
        <metadata-dialog :metadata="item.metadata" />
      </template>
      <template v-slot:item.vulnerabilities="{ item }">
        <vulnerability-dialog
          :vulnerabilities="item.vulnerabilities"
          :number="item.number"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <delete-version-dialog :version="item" @delete="handleDelete" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { fileSize, niceDate } from "@/filters";
import DeleteVersionDialog from "@/components/dialogs/DeleteVersionDialog";
import MetadataDialog from "@/components/dialogs/MetadataDialog";
import VulnerabilityDialog from "@/components/dialogs/VulnerabilityDialog";
import DefaultButton from "@/components/widgets/DefaultButton";

export default {
  props: ["versions", "pkg"],
  components: {
    DefaultButton,
    VulnerabilityDialog,
    DeleteVersionDialog,
    MetadataDialog,
  },
  filters: { fileSize, niceDate },
  methods: {
    clipboardSuccessHandler() {
      this.snackbar = true;
    },
    installMethod(number) {
      return `pip install --extra-index-url ${this.pkg.indexUrl} ${this.pkg.name}==${number}`;
    },
    handleDelete() {
      if (this.pkg.versions.length > 1) {
        this.$emit("refresh");
      } else {
        this.$router.push("/python");
        this.$router.go();
      }
    },
  },
  data: () => ({
    snackbar: false,
    headers: [
      {
        text: "Name",
        value: "number",
      },
      {
        text: "Format",
        value: "fileType",
      },
      {
        text: "Size",
        value: "fileSize",
      },
      {
        text: "Date pushed",
        value: "created",
      },
      {
        text: "Installation instructions",
        value: "install",
      },
      {
        text: "Download",
        value: "download",
      },
      {
        text: "Metadata",
        value: "metadata",
      },
      {
        text: "Vulnerabilities",
        value: "vulnerabilities",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
  }),
};
</script>

<style scoped>
.nice-button {
  text-transform: none !important;
  letter-spacing: 0;
}
</style>
